var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"enterkey",rawName:"v-enterkey"}],ref:"container",staticClass:"c-btn-save",class:{ disabled: _vm.disabled },attrs:{"DA-type":"asyncClick","DA-label":_vm.detail.goods_sn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSaveStatus.apply(null, arguments)}}},[(_vm.showAddBoardBtn && [4, 5].includes(_vm.boardToastPosType))?[_c('div',{class:['btn-save__board', _vm.boardPosClass],on:{"click":function($event){$event.stopPropagation();return _vm.addToBoard.apply(null, arguments)}}},[_c('i',{staticClass:"suiiconfont sui_icon_add_circle_18px"}),_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_17403)+"\n    ")])]:_vm._e(),_vm._v(" "),(_vm.showAddBoardBtn && [4, 5].includes(_vm.boardToastPosType))?[_c('div',{staticClass:"icon-bg",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleSaveStatus.apply(null, arguments)}}})]:_vm._e(),_vm._v(" "),(!_vm.value)?_c('i',{staticClass:"suiiconfont c-btn-save_save-icon sui_icon_save_32px",class:{
      'c-btn-save_anim-start': _vm.isSaveStartAnim && !_vm.isSaveEndAnim,
      'c-btn-save_anim-end': _vm.isSaveEndAnim
    }}):_c('i',{staticClass:"suiiconfont c-btn-save_save-icon sui_icon_save_completed_32px",class:{
      'c-btn-save_anim-start': _vm.isSaveStartAnim && !_vm.isSaveEndAnim,
      'c-btn-save_anim-end': _vm.isSaveEndAnim
    }}),_vm._v(" "),_c('div',{staticClass:"preload-image",staticStyle:{"display":"none"}},[_vm._l((_vm.imageSrc),function(img){return [_c('img',{key:img,staticStyle:{"width":"100%"},attrs:{"src":img}})]})],2),_vm._v(" "),(_vm.showAddBoardBtn && ![4, 5].includes(_vm.boardToastPosType))?[_c('div',{class:['btn-save__board', _vm.boardPosClass],on:{"click":function($event){$event.stopPropagation();return _vm.addToBoard.apply(null, arguments)}}},[_c('i',{staticClass:"suiiconfont sui_icon_add_circle_18px"}),_vm._v("\n      "+_vm._s(_vm.language.SHEIN_KEY_PWA_17403)+"\n    ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }