import { isFunction } from 'lodash';
/**
 * 组合跳转到登陆界面需要的埋点信息
 * 
 * @param {Object} content 专题内容
 * @param {Object} propDate 专题内容中具体的组件，比如pk组件
 * @returns 返回拼接的内容
 */
export const composeCampaign = function(content = {}, propDate = {}) {
    // 获取专题名称
    const { name_en = '' } = content;
    // 获取组件类型id, 获取组件名称
    const { component_id = '', name = '' } = propDate;
    return `campaign_${name_en}_${component_id}_${name}`;
  }

/**
 * 组合跳转到登陆界面需要的埋点信息
 * 
 * @param {Object} content 专题内容
 * @param {Object} propDate 专题内容中具体的组件，比如pk组件
 * @param {Function} 登录成功后的
 */
export function toCampaignLogin(content, propDate, cb) {
    const activity_sign = composeCampaign(content, propDate);
    console.log(activity_sign);
    SHEIN_LOGIN.show({ 
        show: true,
        cb: isFunction(cb) ? cb : () => { 
            window.location.reload()
        },
        ga: activity_sign,
        sensors:  activity_sign
    })
}